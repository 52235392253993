.home__container {
  row-gap: 7rem;
  /* margin-bottom: 20rem; */
}

.home__content {
  /* grid-template-columns:  repeat(2, 1fr); */
  display: flex;
  justify-content: center;
  /* padding-top: 5.5rem; */
  padding-top: 3rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__button {
  gap: 5px;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
  color: var(--title-color);
}

.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
  color: var(--title-color);
}

.home__subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 1rem;
}

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
}

.home__img {
  background: url(../../assets/imac2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  order: 1;
  justify-self: center;
  width: 400px;
  height: 300px;
  border-radius: 1rem;
}

@keyframes profile__animation {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.home__scroll {
  margin-left: 9.25rem;
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}

/* ========== BREAKPOINTS ========== */
/* For large deivice */

@media screen and (max-width: 992px) {
  .home__content {
    /* grid-template-columns: 100px repeat(2, 1fr); */
    column-gap: 1.25rem;
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    /* max-width: initial; */
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 400px;
    height: 250px;
  }

  .home__scroll {
    margin-left: 7.5rem;
  }
}

@media screen and (max-width: 895px) {
  .home__img {
    width: 350px;
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .home__content {
    /* grid-template-columns: 0.5fr 3fr; */
    display:flex;
    padding-top: 3.5rem;
    margin-left: 2rem;
  }
.home__social{
  margin-right: 1rem;
}
  .home__img {
    order: initial;
    justify-self: initial;
    width: 300px;
    height: 200px;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__scroll {
    display: none;
  }
  .home__description {
    width: 70%;
  }
}

/* For small deivice */

@media screen and (max-width: 576px) {
  .home__content {
    margin-left: initial;
  }
  .home__description {
    width: initial;
  }
}

@media screen and (max-width: 350px) {
  .home__img {
    width: 250px;
    height: 180px;
  }
}
