.experience__container {
  max-width: 768px;
}

.experience__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: var(--mb-2);
}

.experience__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
  margin: 0 var(--mb-1);
  cursor: pointer;
}

.experience__button:hover {
  color: var(--title-color-dark);
}

.experience__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

.experience__active {
  color: var(--title-color-dark);
}

.experience__sections {
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
}

.experience__content {
  display: none;
}

.experience__content-active {
  display: block;
}

.experience__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.experience__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
}

.experience__subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
}

.experience__calendar {
  margin-top: -5px;
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  gap: 5px;
}

.calendar-icon {
  margin-top: 4px;
}

.experience__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--title-color);
  border-radius: 50%;
}

.experience__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--title-color);
  transform: translate(6px, -7px);
}

@media screen and (max-width: 992px) {
  .experience__container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 768px) {
  .experience__container {
    margin-left: (--mb-1-5);
    margin-right: (--mb-1-5);
  }
}
@media screen and (max-width: 576px) {
  .experience__sections {
    grid-template-columns: initial;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .experience__button {
    margin: 0 var(--mb-0-75);
  }
}
@media screen and (max-width: 350px) {
  .experience__data {
    gap: 0.5rem;
  }
}
