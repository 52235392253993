.skills__container {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  justify-content: center;
}
.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.2); 
  border-radius: 1.25rem;
}
.skills__title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
  
}
.skills__title {
  font-size: 1rem;
  font-weight: var(--font-medium);
  color: var(--title-color);
}

.skills-title__icon {
  font-size: 2rem;
  color: var(--title-color);
}

.line{
  width: 5rem;
  height: 2px;
  background-color: var(--title-color);
  margin-top: .25rem;
}

.skills__box {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  margin-bottom: 3rem;
}

.skills__group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.75rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.skills__icon {
  font-size: 1.1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
  color: var(--title-color);
}

.skills__level {
  font-size: var(--tiny-font-size);
}

/* Large device  */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: repeat(2, 350px);
    row-gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .skills__container {
    grid-template-columns: repeat(1, 400px);
  }
}

/* Medium devices */
@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
    padding: 20px;
  }
}

/* Small Devices  */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}
