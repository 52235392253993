.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav__logo,
.nav_toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  column-gap: 2rem;
  letter-spacing: 1px;
}

.nav__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--normal-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.nav__close,
.nav__toggle {
  display: none;
}

.active-link,
.nav__link:hover {
  color: var(--title-color-dark);
}

.nav__social-list {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.nav__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.nav__icon {
  display: none;
}

/* For medium deivice */

@media screen and (max-width: 768px) {
  .nav {
    height: var(--header-height);
    justify-content: space-between;
  }

  .nav__social {
    display: none;
  }

  .nav__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 1.5rem 1.5rem;
    transition: 0.3s;
  }

  .nav__link {
    flex-direction: column;
  }

  /* Show menu  */
  .show-menu {
    top: 0;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    top: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }

  .nav__toggle,
  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.2rem;
    cursor: pointer;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }
}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }
  .nav__list {
    column-gap: 0;
  }
}
