.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--container-color);
}

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}
