.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.work__item {
  padding: 0.25rem 0.75rem;
  color: var(--title-color);
  cursor: pointer;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}
.active-work {
  background-color: var(--title-color-dark);
  color: var(--container-color);
}

.work__item:hover {
  background-color: var(--title-color-dark);
  color: var(--container-color);
}

.work__container {
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  gap: 3rem;
}

.work__card {
  display: flex;
  flex-direction: column;
  width: 350px;
  background-color: var(--card-color-v1);
  border-radius: 1.1rem;
  border: 2px solid rgba(0, 0, 0, 0.3);
}
.work__img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.work__img {
  height: 100%;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.work__info-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2.25rem 1.25rem 0 1.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
}
.work__title {
  margin-left: 15px;
  font-size: 1.3rem;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.work__subtitle {
  margin-left: 15px;
  font-size: 1.1rem;
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-75);
  color: var(--title-color);
}

.work__description {
  margin-left: 15px;
  font-size: var(--normal-font-size);
  margin-bottom: var(--mb-0-75);
  font-weight: var(--font-normal);
  /* border: 1px solid red; */
}

.work__technologies-container {
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: var(--mb-1);
}

.work__technologies-title {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}
.work__tech-items {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: rgb(229, 231, 235);
  /* background-color: var(--container-color); */
  /* color: var(--text-color); */
  color: var(--title-color);
  border-radius: 1rem;
  /* font-size: 0.8rem; */
  font-size: var(--smaller-font-size);
  font-weight: var(--font-semi-bold);
  padding: 2px 5px 2px 5px;
  /* border: 1px var(--text-color) solid; */
}

.work__button-container {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  padding: 0 1.25rem 1.25rem 1.25rem;
  border-radius: 0 0 10px 10px;
}

.work__button {
  margin-left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: var(--title-color);
  border-radius: 1rem;
  font-size: var(--smaller-font-size);
  color: var(--container-color);
  padding: 10px;
  font-weight: var(--font-semi-bold);
}
.read_more-btn {
  background-color: var(---color);
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover {
  color: var(--container-color);
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

@media screen and (max-width: 1100px) {
  .work__container {
    grid-template-columns: repeat(2, max-content);
    gap: 1.25rem;
  }
}
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }

 .work__info-container{
    padding: 2rem 1rem 0 1rem;
 }
 .work__button-container{
  padding: 0 1rem 1rem 1rem;
 }

  .work__description {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .work__img {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }
}
